'use client';

// next
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import Image from 'next/legacy/image';
import NextLink from 'next/link';
// project import
import { FormattedMessage } from 'react-intl';
import { useDefaultPath } from '~/hooks/useDefaultPath';

// assets
const error500 = '/assets/images/maintenance/FitxError500.png';

// ==============================|| ERROR 500 - MAIN ||============================== //

function Error500() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const APP_DEFAULT_PATH = useDefaultPath();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '80vh' }}>
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 350, sm: 396 } }}>
          <Image src={error500} alt="mantis" layout="fixed" width={matchDownSM ? 350 : 396} height={matchDownSM ? 325 : 370} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack mt={3} spacing={3} justifyContent="center" alignItems="center">
          <Typography align="center" variant={matchDownSM ? 'h3' : 'h1'}>
            <FormattedMessage id="internal-server-error-title" />
          </Typography>
          <Typography color="textSecondary" variant="h6" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
            <FormattedMessage id="internal-server-error-subtitle" />
          </Typography>
          <NextLink prefetch={false} href={APP_DEFAULT_PATH} passHref legacyBehavior>
            <Button variant="contained" sx={{ textTransform: 'none', mt: 4 }}>
              <FormattedMessage id="return-home" />
            </Button>
          </NextLink>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Error500;
